import { render, staticRenderFns } from "./FlatFilter.vue?vue&type=template&id=20d0eedd&scoped=true&"
import script from "./FlatFilter.vue?vue&type=script&lang=js&"
export * from "./FlatFilter.vue?vue&type=script&lang=js&"
import style0 from "./FlatFilter.vue?vue&type=style&index=0&id=20d0eedd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20d0eedd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/macos/workspaces/winning2024/pdf-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20d0eedd')) {
      api.createRecord('20d0eedd', component.options)
    } else {
      api.reload('20d0eedd', component.options)
    }
    module.hot.accept("./FlatFilter.vue?vue&type=template&id=20d0eedd&scoped=true&", function () {
      api.rerender('20d0eedd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dm/institutionGovernanceReport/components/FlatFilter.vue"
export default component.exports