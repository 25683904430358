var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShowOptions,
          expression: "isShowOptions",
        },
      ],
      ref: "gradeForm",
      staticClass: "grade-from-class",
      attrs: { model: _vm.gradeForm, rules: _vm.rules, "label-width": "80px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "打分等级", prop: "level" } },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "level-radio-group",
              on: { change: _vm.levelChange },
              model: {
                value: _vm.gradeForm.level,
                callback: function ($$v) {
                  _vm.$set(_vm.gradeForm, "level", $$v)
                },
                expression: "gradeForm.level",
              },
            },
            [
              _c(
                "el-radio-button",
                { attrs: { label: "1", border: "" } },
                [_c("svg-icon", { attrs: { "icon-class": "wenhao" } })],
                1
              ),
              _c(
                "el-radio-button",
                { attrs: { label: "2", border: "" } },
                [_c("svg-icon", { attrs: { "icon-class": "duihao2" } })],
                1
              ),
              _c(
                "el-radio-button",
                { attrs: { label: "3", border: "" } },
                [_c("svg-icon", { attrs: { "icon-class": "kc-empty" } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "问题类型", prop: "problemList" } },
        [
          _vm.isShowError
            ? _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.gradeForm.problemList,
                    callback: function ($$v) {
                      _vm.$set(_vm.gradeForm, "problemList", $$v)
                    },
                    expression: "gradeForm.problemList",
                  },
                },
                _vm._l(_vm.PROBLEM_OPTIONS, function (option) {
                  return _c(
                    "el-radio-button",
                    { key: option.value, attrs: { label: option.value } },
                    [_vm._v(_vm._s(option.label))]
                  )
                }),
                1
              )
            : _c("span", [_vm._v("无")]),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "风险级别", prop: "riskList" } },
        [
          _vm.isShowError
            ? _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.gradeForm.riskList,
                    callback: function ($$v) {
                      _vm.$set(_vm.gradeForm, "riskList", $$v)
                    },
                    expression: "gradeForm.riskList",
                  },
                },
                _vm._l(_vm.RISK_OPTIONS, function (option) {
                  return _c(
                    "el-radio-button",
                    { key: option.value, attrs: { label: option.value } },
                    [_vm._v(_vm._s(option.label))]
                  )
                }),
                1
              )
            : _c("span", [_vm._v("无")]),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticStyle: { "text-align": "right", "margin-right": "8px" } },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.onCancel } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.onConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }