<!--治理报告打分级联-->
<template>
  <ul class="filter-cascader-ul box-shadow" v-show="isShowOptions">
    <!-- <li
      v-for="(item, index) in filterOptions"
      :key="index"
      class="cascader-li"
      :class="{ 'active': item.showChildren }"
      @mouseover="filterOptionHover(item)"
      @click="onClickGradeStatus(item)"> -->
      <li
        v-for="(item, index) in gradeOptions"
        :key="index"
        class="cascader-li"
        :class="{ 'active': item.showChildren }"
        @mouseover="filterOptionHover(item)"
        @click="onClickGradeStatus(item)">
      <svg-icon
        slot="reference"
        :icon-class="item.icon"
        class="filter-icon"/>
      <ul
        v-show="item.children && item.showChildren"
        class="children-ul box-shadow"
        :class="positionChildrenUlClass">
        <li class="children-li" style="text-align: center;">
          <el-form ref="gradeForm" :model="gradeForm" :rules="rules">
            <el-form-item label="问题类型" prop="problemList">
              <el-select v-model="gradeForm.problemList">
                <el-option
                  v-for="option in PROBLEM_OPTIONS"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="风险级别" prop="riskList">
              <el-select v-model="gradeForm.riskList">
                <el-option
                  v-for="option in RISK_OPTIONS"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </li>
        <el-button type="primary" size="small" @click="onConfirm(item)">确定</el-button>
      </ul>
    </li>
  </ul>
</template>
<script>
import {
  GRADE_OPTIONS,
  PROBLEM_OPTIONS,
  RISK_OPTIONS
} from '@/utils/dm/institutionGovernanceReport/data'

export default {
  name: 'FilterCascader',
  props: {
    showFilter: { // 筛选模式切换
      type: Boolean,
      default: false
    },
    filterOptions: {
      type: Array,
      required: true
    },
    status: {
      type: String
    },
    position: {
      type: String,
      default: 'right'
    },
    problemType: {
      type: String,
      default: ''
    },
    riskLevel: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // filterOptions: this.$deepClone(FILTER_OPTIONS),
      gradeOptions: this.$deepClone( GRADE_OPTIONS),
      isShowOptions: false,
      PROBLEM_OPTIONS: this.$deepClone(PROBLEM_OPTIONS),
      RISK_OPTIONS: this.$deepClone(RISK_OPTIONS),
      gradeForm: {
        riskList: '',
        problemList: '',
      },
      scope: null,
      rules: {
				riskList: [
					{ required: true, trigger: "blur", message: "必填" }
				],
				problemList: [
					{ required: true, trigger: "blur", message: "必填" }
				]
			}
    }
  },
  computed: {
    showIndex() {
      let index = 0
      index = this.filterOptions.findIndex(item => item.value === this.status)
      return index
    },
    positionChildrenUlClass() {
      if (this.position === 'left-center') {
        return 'children-ul left-center-children-ul'
      } else if (this.position === 'right-center') {
        return 'children-ul right-center-children-ul'
      } else if (this.position === 'top-center') {
        return 'children-ul top-center-children-ul'
      }
    }
  },
  watch: {
    showFilter: function(newVal, oldVal) {
      if (newVal) {
        this.isShowOptions = true
      }
      if (!oldVal) {
        this.isShowOptions = false
      }
    }
  },
  created() {
    // console.log(this.problemType)
    // console.log(this.riskLevel)
    this.gradeForm.riskList = this.riskLevel
    this.gradeForm.problemList = this.problemType
  },
  mounted() {
    this.$nextTick(() => {
      const content = this.$refs.content
      if (content) {
        document.body.appendChild(content)
      }
    })
  },
  beforeDestroy() {
    let { popover, content } = this.$refs
    // off(document, "click", this.close);
    if (document.body.contains(content)) {
      document.body.removeChild(content)
    }
  },
  methods: {
    onFilterClick(scope) {
      // console.log(scope)
      this.scope = scope
      this.isShowOptions = !this.isShowOptions
    },
    // 显示的时候进行数据初始化
    initHandleData(scope) {
      this.scope = scope
      // console.log(this.scope)
      this.gradeOptions.find(item => {
        if (item.value === this.scope.row[this.scope.column.property]) {
          item.showChildren = true
        }
      })
    },
    filterOptionHover(item) {
      // this.filterOptions.forEach(option => {
      //   if (option.value !== item.value) {
      //     option.showChildren = false
      //   }
      // })
      this.gradeOptions.forEach(option => {
        if (option.value !== item.value) {
          option.showChildren = false
        }
      })
      item.showChildren = true
    },
    onConfirm(item) {
      this.$refs['gradeForm'][0].validate(async (valid) => {
				if (valid) {
					this.isShowOptions = false
          const selectObj = {
            parentItem: item,
            checkList: this.gradeForm
          }
          // console.log(selectObj)
          this.$emit('closeFilter', selectObj, this.scope)
				} else {
					console.log('error submit!!');
					return false
				}
			})
    },
    // 疑问和成功点击事件
    onClickGradeStatus(item) {
      if (item.value === '3') {
        return
      }
      this.isShowOptions = false
      const selectObj = {
        parentItem: item,
        checkList: this.gradeForm
      }
      // console.log(selectObj)
      this.$emit('closeFilter', selectObj, this.scope)
    }
  }
}
</script>
<style lang="scss" scoped>
ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.el-popover {
  padding: 0 !important;
}
.box-shadow {
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(87, 126, 255, 0.8);
  border-radius: 3px;
}
.filter-icon {
  width: 18px;
  height: 18px;
}
.el-select {
  width: 106px !important;
}
.filter-popover{
  display: inline-block;
  vertical-align: top;
  position: relative;
  left: 50px;
  .content-wrapper{
    font-size: 14px;
    position: absolute;
    bottom: 100%;
    left: 0;
    box-shadow: 0 0 3px rgba(0,0,0,0.5);
  }
}
.filter-cascader-ul {
  font-size: 14px;
  position: relative;
  /* top: -12px;
  left: -12px;
  width: calc(100% + 24px);
  height: calc(100% + 24px); */
  width: 100%;
  height: 100%;
  .cascader-li {
    position: relative;
    padding: 10px 0;
    text-align: center;
    &.active {
      color: #2B60F8;
      background: #EDF3FF;
    }
    &:hover {
      cursor: default;
    }
    .children-ul {
      width: 214px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(80px, -22px);
      padding: 15px;
      &.left-center-children-ul {
        left: -300px;
        top: 20px;
      }
      &.top-center-children-ul {
        left: -80px;
        top: -170px;
      }
      .children-li {
        padding: 5px 0;
        text-align: left;
        form {
          ::v-deep.el-form-item {
            label {
              font-size: 14px;
              color: #333;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
</style>
