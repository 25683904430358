<!--治理报告打分级联(第二个版本)-->
<template>
  <el-form
    ref="gradeForm"
    :model="gradeForm"
    :rules="rules"
    v-show="isShowOptions"
    label-width="80px"
    class="grade-from-class">
    <el-form-item label="打分等级" prop="level">
      <el-radio-group v-model="gradeForm.level" class="level-radio-group" @change="levelChange">
        <el-radio-button label="1" border>
          <svg-icon icon-class="wenhao" />
        </el-radio-button>
        <el-radio-button label="2" border><svg-icon icon-class="duihao2" /></el-radio-button>
        <el-radio-button label="3" border><svg-icon icon-class="kc-empty" /></el-radio-button>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="问题类型" prop="problemList">
      <el-radio-group v-if="isShowError" v-model="gradeForm.problemList" size="small">
        <el-radio-button
          v-for="option in PROBLEM_OPTIONS"
          :key="option.value"
          :label="option.value">{{ option.label }}</el-radio-button>
      </el-radio-group>
      <span v-else>无</span>
    </el-form-item>
    <el-form-item label="风险级别" prop="riskList">
      <el-radio-group v-if="isShowError" v-model="gradeForm.riskList" size="small">
        <el-radio-button
          v-for="option in RISK_OPTIONS"
          :key="option.value"
          :label="option.value">{{ option.label }}</el-radio-button>
      </el-radio-group>
      <span v-else>无</span>
    </el-form-item>
    <el-form-item style="text-align: right;margin-right: 8px;">
      <el-button size="small" @click="onCancel">取消</el-button>
      <el-button type="primary" size="small" @click="onConfirm">确定</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import {
  GRADE_OPTIONS,
  PROBLEM_OPTIONS,
  RISK_OPTIONS
} from '@/utils/dm/institutionGovernanceReport/data'

export default {
  name: 'PlanarGrade',
  props: {
    showFilter: { // 筛选模式切换
      type: Boolean,
      default: false
    },
    isBatch: { // 是否是批量评审模式
      type: Boolean,
      default: false
    },
    currentClickLevel: { // 点击的时候是叉号，问号还是对号
      type: String,
      default: '2'
    },
    filterOptions: {
      type: Array,
      required: true
    },
    status: {
      type: String
    },
    position: {
      type: String,
      default: 'right'
    },
    problemType: {
      type: String,
      default: ''
    },
    riskLevel: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // filterOptions: this.$deepClone(FILTER_OPTIONS),
      gradeOptions: this.$deepClone( GRADE_OPTIONS),
      isShowOptions: false,
      isShowError: true,
      PROBLEM_OPTIONS: this.$deepClone(PROBLEM_OPTIONS),
      RISK_OPTIONS: this.$deepClone(RISK_OPTIONS),
      gradeForm: {
        level: '',
        riskList: '',
        problemList: '',
      },
      scope: null,
      rules: {
        level: [
          { required: true, trigger: ['blur', 'change'], message: "必填" }
        ],
				riskList: [
					{ required: true, trigger: ['blur', 'change'], message: "必填" }
				],
				problemList: [
					{ required: true, trigger: ['blur', 'change'], message: "必填" }
				]
			}
    }
  },
  computed: {
    showIndex() {
      let index = 0
      index = this.filterOptions.findIndex(item => item.value === this.status)
      return index
    },
    positionChildrenUlClass() {
      if (this.position === 'left-center') {
        return 'children-ul left-center-children-ul'
      } else if (this.position === 'right-center') {
        return 'children-ul right-center-children-ul'
      } else if (this.position === 'top-center') {
        return 'children-ul top-center-children-ul'
      }
    }
  },
  watch: {
    showFilter: function(newVal, oldVal) {
      if (newVal) {
        this.isShowOptions = true
      }
      if (!oldVal) {
        this.isShowOptions = false
      }
    },
    isShowOptions(newVal, oldVal) {
      this.$refs['gradeForm'].clearValidate()
      if (newVal) {
        if (Number(this.currentClickLevel) === 2) {
          this.gradeForm.level = '3'
        } else if (Number(this.currentClickLevel) === 3) {
          this.gradeForm.level = '3'
        } else if (Number(this.currentClickLevel) === 3) {
          this.gradeForm.level = '1'
        }
      }
    },
    'gradeForm.level': function(newVal, oldVal) {
      if (newVal === '2' || newVal === '1' ) {
        // this.isShowError = false
      } else {
        // this.isShowError = true
      }
    }
  },
  created() {
    // console.log(this.problemType)
    // console.log(this.riskLevel)
    this.gradeForm.riskList = this.riskLevel
    this.gradeForm.problemList = this.problemType
  },
  mounted() {
    this.$nextTick(() => {
      const content = this.$refs.content
      if (content) {
        document.body.appendChild(content)
      }
    })
  },
  beforeDestroy() {
    let { popover, content } = this.$refs
    // off(document, "click", this.close);
    if (document.body.contains(content)) {
      document.body.removeChild(content)
    }
  },
  methods: {
    onFilterClick(scope) {
      this.scope = scope
      this.isShowOptions = !this.isShowOptions
    },
    // 显示的时候进行数据初始化
    initHandleData(scope) {
      this.scope = scope
      this.gradeOptions.find(item => {
        if (item.value === (this.scope.row && this.scope.row[this.scope.column.property])) {
          item.showChildren = true
        }
      })
    },
    filterOptionHover(item) {
      this.gradeOptions.forEach(option => {
        if (option.value !== item.value) {
          option.showChildren = false
        }
      })
      item.showChildren = true
    },
    onConfirm() {
      // validateField
      const { level } = this.gradeForm
      if (level === '1' || level === '2') {
        this.submitForm()
        return
      }
      this.$refs['gradeForm'].validate(async (valid) => {
				if (valid) {
					this.submitForm()
				} else {
					console.log('error submit!!');
					return false
				}
			})
    },
    // 表单提交处理函数
    submitForm() {
      // this.isShowOptions = false
      // const selectObj = {
      //   parentItem: item,
      //   checkList: this.gradeForm
      // }
      const selectObj = {
        level: this.gradeForm.level,
        problemList: this.gradeForm.problemList,
        riskList: this.gradeForm.riskList
      }
      this.$emit('closeFilter', selectObj, this.scope)
    },
    // 疑问和成功点击事件
    onClickGradeStatus(item) {
      if (item.value === '3') {
        return
      }
      this.isShowOptions = false
      const selectObj = {
        parentItem: item,
        checkList: this.gradeForm
      }
      // console.log(selectObj)
      this.$emit('closeFilter', selectObj, this.scope)
    },
    onCancel() {
      // 取消
      this.$emit('closeFilter', undefined, this.scope)
    },
    levelChange(val) {
      if (val === '1' || val === '2') {
        this.$refs['gradeForm'].clearValidate(['problemList', 'riskList'])
        const selectObj = {
          level: this.gradeForm.level,
          problemList: this.gradeForm.problemList,
          riskList: this.gradeForm.riskList
        }
        this.$emit('closeFilter', selectObj, this.scope)
        this.gradeForm.level = '3'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.el-popover {
  padding: 0 !important;
}
.box-shadow {
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(87, 126, 255, 0.8);
  border-radius: 3px;
}
.filter-icon {
  width: 18px;
  height: 18px;
}
.el-select {
  width: 106px !important;
}
.filter-popover{
  display: inline-block;
  vertical-align: top;
  position: relative;
  left: 50px;
  .content-wrapper{
    font-size: 14px;
    position: absolute;
    bottom: 100%;
    left: 0;
    box-shadow: 0 0 3px rgba(0,0,0,0.5);
  }
}
.grade-from-class {
  padding: 10px;
  padding-bottom: 0;
  width: 334px;
  .el-form-item {
    margin-bottom: 10px;
    ::v-deep .level-radio-group .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background-color: #B0BFE8;
    }
    ::v-deep .level-radio-group .el-radio-button__orig-radio + .el-radio-button__inner {
      &:hover {
        border: 1px solid $topic-color;
      }
    }
  }
  ::v-deep.el-form-item__error {
    top: 100%;
    margin-top: -8px;
  }
  .el-radio-button {
    margin-right: 8px;
    margin-bottom: 8px;
    &:last-child {
      margin: 0;
    }
    ::v-deep span.el-radio-button__inner {
      border-radius: 4px;
      border: 1px solid #B0BFE8;
      width: 70px;
      padding: 7px 0;
      text-align: center;
    }
    ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      box-shadow: none;
      background-color: #B0BFE8;
    }
  }
}
</style>
