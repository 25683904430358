var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "simple-check-report-dialog vertical-middle-dialog",
          attrs: {
            title: "匹配预览",
            visible: _vm.dialogVisible,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "800px",
            "append-to-body": true,
            center: "",
            "before-close": _vm.handleClose,
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "no-map-table",
              staticClass: "no-match-report-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.currentRowTableData,
                "header-cell-style": { textAlign: "center" },
                "cell-class-name": _vm.cellClassName,
                height: "100%",
                stripe: "",
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "字段名称",
                  width: "200",
                  prop: "fieldNameStr",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v(" " + _vm._s(scope.row.fieldNameStr) + " ")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  934152334
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "原始数据", prop: "orgRelatedName" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.orgRelatedName)),
                          ]),
                          _vm.isShowErrorDetails(scope)
                            ? _c(
                                "span",
                                {
                                  staticClass: "error-details fr",
                                  staticStyle: { margin: "0 5px" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(scope.row.errorTypeStr) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  690340983
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "标准数据", prop: "platformRelatedName" },
              }),
              _vm.showOperation
                ? _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      prop: "status",
                      width: "80",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row[scope.column.property] !== "1"
                                ? _c(
                                    "el-popover",
                                    {
                                      ref:
                                        "gradePopover" +
                                        scope.column.property +
                                        scope.$index,
                                      attrs: {
                                        trigger: "click",
                                        placement: "bottom",
                                        "visible-arrow": false,
                                        "popper-class": "governance-poper",
                                      },
                                      on: {
                                        hide: function ($event) {
                                          return _vm.onHidePopover(
                                            scope,
                                            "grade-cascader"
                                          )
                                        },
                                        show: function ($event) {
                                          return _vm.onShowPopover(
                                            scope,
                                            "grade-cascader"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm.showGradeComponent(scope)
                                        ? _c("planar-grade", {
                                            ref:
                                              "grade-cascader" +
                                              scope.column.property +
                                              scope.$index,
                                            attrs: {
                                              status:
                                                scope.row[
                                                  scope.column.property
                                                ],
                                              "show-filter": _vm.isFilter,
                                              currentClickLevel:
                                                scope.row[
                                                  scope.column.property
                                                ],
                                              "filter-options":
                                                _vm.gradeOptions,
                                              "problem-type":
                                                _vm.problemTypeProp(scope),
                                              "risk-level":
                                                _vm.riskLevelProp(scope),
                                              position:
                                                _vm.gradePosition[
                                                  scope.column.property +
                                                    "" +
                                                    scope.row.drugId +
                                                    "" +
                                                    scope.$index
                                                ],
                                            },
                                            on: {
                                              closeFilter:
                                                _vm.onCloseGradeFilter,
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [
                                          _c("svg-icon", {
                                            staticClass: "filter-icon",
                                            staticStyle: {
                                              position: "absolute",
                                              right: "16px",
                                              top: "50%",
                                              transform:
                                                "translate(-50%, -50%)",
                                              width: "18px",
                                              height: "18px",
                                            },
                                            attrs: {
                                              "icon-class": _vm.gradeIcon(
                                                scope.row[scope.column.property]
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.gradeIconClick(scope)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : scope.row[scope.column.property] === "1"
                                ? _c("svg-icon", {
                                    staticClass: "filter-icon",
                                    staticStyle: {
                                      position: "absolute",
                                      right: "16px",
                                      top: "50%",
                                      transform: "translate(-50%, -50%)",
                                      width: "18px",
                                      height: "18px",
                                    },
                                    attrs: {
                                      "icon-class": _vm.gradeIcon(
                                        scope.row[scope.column.property]
                                      ),
                                      "fill-color": "#0073E9",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.gradeIconClick(scope)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3855269284
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onPrev } },
                [_vm._v("上一条")]
              ),
              _c("el-button", { on: { click: _vm.onNext } }, [
                _vm._v("下一条"),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }