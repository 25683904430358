<!--治理报告筛选级联-->
<template>
  <!-- <div v-if="showFilter" class="filter-popover">
    <slot name="content">
      <ul
        ref="content"
        v-show="isShowOptions"
        class="filter-cascader-ul box-shadow content-wrapper">
        <li
          v-for="(item, index) in filterOptions"
          :key="index"
          class="cascader-li"
          :class="{ 'active': item.showChildren }"
          @mouseover="filterOptionHover(item)">
          {{ item.label }}
          <ul v-if="item.children && item.showChildren" class="children-ul box-shadow">
            <el-checkbox-group v-model="checkList">
              <li v-for="child in item.children" :key="child.value" class="children-li">
                <el-checkbox :label="child.value">{{ child.label }}</el-checkbox>
              </li>
            </el-checkbox-group>
            <el-button type="primary" size="small" @click="onConfirm(item)">确定</el-button>
          </ul>
        </li>
      </ul>
    </slot>
    <slot>
      <svg-icon
        slot="reference"
        icon-class="shaixuan"
        class="filter-icon"
        style="position: absolute; right: 10px;width: 20px;height: 20px;"
        @click="onFilterClick"/>
    </slot>
  </div> -->
  <ul class="filter-cascader-ul box-shadow" v-show="isShowOptions">
    <li
      v-for="(item, index) in filterOptions"
      :key="index"
      class="cascader-li"
      :class="{ 'active': item.showChildren }"
      @mouseover="filterOptionHover(item)">
      <span>{{ item.label }}</span>
      <i class="el-icon-caret-right icon-class"/>
      <!-- <i v-if="position === 'left'" class="el-icon-caret-left icon-class"/> -->
      <ul v-if="item.children && item.showChildren" class="box-shadow" :class="positionChildrenUlClass">
        <el-checkbox-group v-if="item.value === '1'" v-model="riskLevelCheckList">
          <li v-for="child in item.children" :key="child.value" class="children-li">
            <el-checkbox :label="child.value">{{ child.label }}</el-checkbox>
          </li>
        </el-checkbox-group>
        <el-checkbox-group v-else-if="item.value === '2'" v-model="problemCheckList">
          <li v-for="child in item.children" :key="child.value" class="children-li">
            <el-checkbox :label="child.value">{{ child.label }}</el-checkbox>
          </li>
        </el-checkbox-group>
        <el-button type="primary" size="small" @click="onConfirm(item)">确定</el-button>
      </ul>
    </li>
  </ul>
</template>
<script>
import {
  FILTER_OPTIONS
} from '@/utils/dm/institutionGovernanceReport/data'
export default {
  name: 'FilterCascader',
  props: {
    showFilter: {
      type: Boolean,
      default: false
    },
    filterOptions: {
      type: Array,
      required: true
    },
    column: {
      type: Object,
      required: true
    },
    position: {
      type: String,
      default: 'right'
    }
  },
  data() {
    return {
      // filterOptions: this.$deepClone(FILTER_OPTIONS),
      isShowOptions: false,
      scope: null,
      checkList: [],
      problemCheckList: [],
      riskLevelCheckList: []
    }
  },
  computed: {
    positionChildrenUlClass() {
      if (this.position === 'left') {
        return 'children-ul left-children-ul'
      } else if (this.position === 'right') {
        return 'children-ul right-children-ul'
      }
    }
  },
  watch: {
    showFilter: function(newVal, oldVal) {
      if (newVal) {
        this.isShowOptions = true
      }
      if (!oldVal) {
        this.isShowOptions = false
      }
    },
    isShowOptions(newVal, oldVal) {
      // console.log(newVal)
    }
  },
  created() {
    // console.log(this.column)
  },
  mounted() {
    this.$nextTick(() => {
      const content = this.$refs.content
      if (content) {
        document.body.appendChild(content)
      }
    })
  },
  beforeDestroy() {
    let { popover, content } = this.$refs
    if (document.body.contains(content)) {
      document.body.removeChild(content)
    }
  },
  methods: {
    onFilterClick(scope) {
      // console.log(scope)
      // this.isShowOptions = !this.isShowOptions
      this.isShowOptions = true
      this.scope = scope
      this.$emit('filterChange', this.isShowOptions)
    },
    filterOptionHover(item) {
      this.filterOptions.forEach(option => {
        if (option.value !== item.value) {
          option.showChildren = false
        }
      })
      item.showChildren = true
    },
    onConfirm(item) {
      this.isShowOptions = false
      let selectObj = {
        parentItem: item,
        checkList: [],
        problemCheckList: [],
        riskLevelCheckList: []
      }
      // if (item.value === '2') {
      //   // 问题类型
      //   selectObj.checkList = this.problemCheckList
      //   selectObj.problemCheckList = this.problemCheckList
      // } else if(item.value === '1') {
      //   // 风险级别
      //   selectObj.checkList = this.riskLevelCheckList
      //   selectObj.riskLevelCheckList = this.riskLevelCheckList
      // }
      selectObj.problemCheckList = this.problemCheckList
      selectObj.riskLevelCheckList = this.riskLevelCheckList
      this.$emit('closeFilter', selectObj, this.scope)
    },
    // 筛选重置
    onReset() {
      this.problemCheckList = []
      this.riskLevelCheckList = []
    }
  }
}
</script>
<style lang="scss" scoped>
ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.el-popover {
  padding: 0 !important;
}
.box-shadow {
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(87, 126, 255, 0.8);
  border-radius: 3px;
}
.filter-popover{
  display: inline-block;
  vertical-align: top;
  position: relative;
  left: 50px;
  .content-wrapper{
    font-size: 14px;
    position: absolute;
    bottom: 100%;
    left: 0;
    box-shadow: 0 0 3px rgba(0,0,0,0.5);
  }
}
.filter-cascader-ul {
  font-size: 14px;
  position: relative;
  /* top: -12px;
  left: -12px;
  width: calc(100% + 24px);
  height: calc(100% + 24px); */
  width: 100%;
  height: 100%;
  .cascader-li {
    position: relative;
    padding: 10px 0;
    text-align: center;
    &.active {
      color: #2B60F8;
      background: #EDF3FF;
    }
    &:hover {
      cursor: default;
    }
    .children-ul {
      width: 160px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(40%, -50%);
      padding: 15px;
      &.left-children-ul {
        left: -230px;
      }
      .children-li {
        padding: 5px 0;
        text-align: left;
      }
    }
  }
}
</style>
