/**机构治理报告函数和方法 */
// 处理图表获取到的数据
import {
  TABLE_COLUMN_LIST
} from './data'
export const chartsDataHandle = function(data) {
  let seriesData = []
  let legendData = []
  for (let item of data) {
    seriesData.push({
      value: item.count,
      name: item.name
    })
    legendData.push(
      {
        name: item.name,
        icon: 'circle',
        textStyle: {
          color: '#333',
          fontSize: 12
        }
      }
    )
  }
  return {
    seriesData,
    legendData
  }
}
// 表格处理
export function handleTableData(tableDataOrigin) {
  let data = []
  // let obj = DEEP_CLONE(TABLE_ITEM)
  for (let i = 0; i < tableDataOrigin.length; i++) {
    // 机构
    let item = tableDataOrigin[i]
    
    let keyItem = {}
    let nextKeyItem = {}
    
    keyItem['drugId'] = item.drugId
    keyItem['ustdCode'] = item.ustdCode
    keyItem['drugIdFrom'] = item.drugId + 'insti'
    keyItem['checked'] = false
    nextKeyItem['checked'] = false
    nextKeyItem['drugId'] = item.drugId
    nextKeyItem['ustdCode'] = item.ustdCode
    nextKeyItem['drugIdFrom'] = item.drugId + 'platform'
    for (let key of TABLE_COLUMN_LIST) {
      keyItem[key] = item[key]['orgRelatedName']
      keyItem[key + 'Status'] = item[key].status
      keyItem[key + 'StatusStr'] = item[key].statusStr
      keyItem[key + 'riskLevel'] = item[key].riskLevel
      keyItem[key + 'riskLevelStr'] = item[key].riskLevelStr
      keyItem[key + 'errorType'] = item[key].errorType
      keyItem[key + 'errorTypeStr'] = item[key].errorTypeStr

      nextKeyItem[key] = item[key]['platformRelatedName']
      nextKeyItem[key + 'Status'] = item[key].status
      nextKeyItem[key + 'StatusStr'] = item[key].statusStr
      nextKeyItem[key + 'riskLevel'] = item[key].riskLevel
      nextKeyItem[key + 'riskLevelStr'] = item[key].riskLevelStr
      nextKeyItem[key + 'errorType'] = item[key].errorType
      nextKeyItem[key + 'errorTypeStr'] = item[key].errorTypeStr
    }
    let startIndex = i + i * 2
    data.splice(startIndex, 0, keyItem, nextKeyItem)
    // data.splice(i, 0, nextKeyItem)
    /*data.push({
      drugId: item.drugId,
      ustdCode: item.ustdCode, // 编码
      genericNameCompare: item.genericNameCompare.orgRelatedName, // 通用名
      specCompare: item.specCompare.orgRelatedName,
      formNameCompare: item.formNameCompare.orgRelatedName,
      manufacturerCompare: item.manufacturerCompare.orgRelatedName,
      approvalNoCompare: item.approvalNoCompare.orgRelatedName,

      genericNameCompareStatus: item.genericNameCompare.status, // 通用名 相关的结果 1:疑问 2:成功 3:失败
      genericNameCompareStatusStr: item.genericNameCompare.statusStr,
      specCompareStatus: item.specCompare.status, // 规格
      specCompareStatusStr: item.specCompare.statusStr,
      formNameCompareStatus: item.formNameCompare.status, // 剂型名称
      formNameCompareStatusStr: item.formNameCompare.statusStr, // 剂型名称
      manufacturerCompareStatus: item.manufacturerCompare.status, // 厂商
      manufacturerCompareStatusStr: item.manufacturerCompare.statusStr, // 厂商
      approvalNoCompareStatus: item.approvalNoCompare.status, // 批准文号
      approvalNoCompareStatusStr: item.approvalNoCompare.statusStr, // 批准文号

      genericNameCompareriskLevel: item.genericNameCompare.riskLevel, // 通用名 风险级别 3为风险项 4为优化项
      genericNameCompareriskLevelStr: item.genericNameCompare.riskLevelStr,
      specCompareriskLevel: item.specCompare.riskLevel, // 规格
      specCompareriskLevelStr: item.specCompare.riskLevelStr,
      formNameCompareriskLevel: item.formNameCompare.riskLevel, // 剂型名称
      formNameCompareriskLevelStr: item.formNameCompare.riskLevelStr, // 剂型名称
      manufacturerCompareriskLevel: item.manufacturerCompare.riskLevel, // 厂商
      manufacturerCompareriskLevelStr: item.manufacturerCompare.riskLevelStr, // 厂商
      approvalNoCompareriskLevel: item.approvalNoCompare.riskLevel, // 批准文号
      approvalNoCompareriskLevelStr: item.approvalNoCompare.riskLevelStr, // 批准文号

      genericNameCompareerrorType: item.genericNameCompare.errorType, // 通用名 风险级别 3为风险项 4为优化项
      genericNameCompareerrorTypeStr: item.genericNameCompare.errorTypeStr,
      specCompareerrorType: item.specCompare.errorType, // 规格
      specCompareerrorTypeStr: item.specCompare.errorTypeStr,
      formNameCompareerrorType: item.formNameCompare.errorType, // 剂型名称
      formNameCompareerrorTypeStr: item.formNameCompare.errorTypeStr, // 剂型名称
      manufacturerCompareerrorType: item.manufacturerCompare.errorType, // 厂商
      manufacturerCompareerrorTypeStr: item.manufacturerCompare.errorTypeStr, // 厂商
      approvalNoCompareerrorType: item.approvalNoCompare.errorType, // 批准文号
      approvalNoCompareerrorTypeStr: item.approvalNoCompare.errorTypeStr // 批准文号
    })
    // 平台
    data.push({
      drugId: item.drugId,
      ustdCode: item.ustdCode, // 编码
      genericNameCompare: item.genericNameCompare.platformRelatedName, // 通用名
      specCompare: item.specCompare.platformRelatedName,
      formNameCompare: item.formNameCompare.platformRelatedName,
      manufacturerCompare: item.manufacturerCompare.platformRelatedName,
      approvalNoCompare: item.approvalNoCompare.platformRelatedName,

      genericNameCompareStatus: item.genericNameCompare.status, // 通用名 相关的结果 1:疑问 2:成功 3:失败
      genericNameCompareStatusStr: item.genericNameCompare.statusStr,
      specCompareStatus: item.specCompare.status, // 规格
      specCompareStatusStr: item.specCompare.statusStr,
      formNameCompareStatus: item.formNameCompare.status, // 剂型名称
      formNameCompareStatusStr: item.formNameCompare.statusStr, // 剂型名称
      manufacturerCompareStatus: item.manufacturerCompare.status, // 厂商
      manufacturerCompareStatusStr: item.manufacturerCompare.statusStr, // 厂商
      approvalNoCompareStatus: item.approvalNoCompare.status, // 批准文号
      approvalNoCompareStatusStr: item.approvalNoCompare.statusStr, // 批准文号

      genericNameCompareriskLevel: item.genericNameCompare.riskLevel, // 通用名 风险级别 3为风险项 4为优化项
      genericNameCompareriskLevelStr: item.genericNameCompare.riskLevelStr,
      specCompareriskLevel: item.specCompare.riskLevel, // 规格
      specCompareriskLevelStr: item.specCompare.riskLevelStr,
      formNameCompareriskLevel: item.formNameCompare.riskLevel, // 剂型名称
      formNameCompareriskLevelStr: item.formNameCompare.riskLevelStr, // 剂型名称
      manufacturerCompareriskLevel: item.manufacturerCompare.riskLevel, // 厂商
      manufacturerCompareriskLevelStr: item.manufacturerCompare.riskLevelStr, // 厂商
      approvalNoCompareriskLevel: item.approvalNoCompare.riskLevel, // 批准文号
      approvalNoCompareriskLevelStr: item.approvalNoCompare.riskLevelStr, // 批准文号

      genericNameCompareerrorType: item.genericNameCompare.errorType, // 通用名 风险级别 3为风险项 4为优化项
      genericNameCompareerrorTypeStr: item.genericNameCompare.errorTypeStr,
      specCompareerrorType: item.specCompare.errorType, // 规格
      specCompareerrorTypeStr: item.specCompare.errorTypeStr,
      formNameCompareerrorType: item.formNameCompare.errorType, // 剂型名称
      formNameCompareerrorTypeStr: item.formNameCompare.errorTypeStr, // 剂型名称
      manufacturerCompareerrorType: item.manufacturerCompare.errorType, // 厂商
      manufacturerCompareerrorTypeStr: item.manufacturerCompare.errorTypeStr, // 厂商
      approvalNoCompareerrorType: item.approvalNoCompare.errorType, // 批准文号
      approvalNoCompareerrorTypeStr: item.approvalNoCompare.errorTypeStr // 批准文号
    }) */
  }
  // console.log(data)
  return data
}
