var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShowOptions,
          expression: "isShowOptions",
        },
      ],
      staticClass: "filter-cascader-ul box-shadow",
    },
    _vm._l(_vm.filterOptions, function (item, index) {
      return _c(
        "li",
        {
          key: index,
          staticClass: "cascader-li",
          class: { active: item.showChildren },
          on: {
            mouseover: function ($event) {
              return _vm.filterOptionHover(item)
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(item.label))]),
          _c("i", { staticClass: "el-icon-caret-right icon-class" }),
          item.children && item.showChildren
            ? _c(
                "ul",
                {
                  staticClass: "box-shadow",
                  class: _vm.positionChildrenUlClass,
                },
                [
                  item.value === "1"
                    ? _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.riskLevelCheckList,
                            callback: function ($$v) {
                              _vm.riskLevelCheckList = $$v
                            },
                            expression: "riskLevelCheckList",
                          },
                        },
                        _vm._l(item.children, function (child) {
                          return _c(
                            "li",
                            { key: child.value, staticClass: "children-li" },
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: child.value } },
                                [_vm._v(_vm._s(child.label))]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : item.value === "2"
                    ? _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.problemCheckList,
                            callback: function ($$v) {
                              _vm.problemCheckList = $$v
                            },
                            expression: "problemCheckList",
                          },
                        },
                        _vm._l(item.children, function (child) {
                          return _c(
                            "li",
                            { key: child.value, staticClass: "children-li" },
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: child.value } },
                                [_vm._v(_vm._s(child.label))]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.onConfirm(item)
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }