<!--治理报告提供单个药品的数据问题浏览页面-->
<template>
  <el-dialog
		v-if="dialogVisible"
    title="匹配预览"
    :visible="dialogVisible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="simple-check-report-dialog vertical-middle-dialog"
    width="800px"
		:append-to-body="true"
    center
    :before-close="handleClose">
    <el-table
      ref="no-map-table"
      :data="currentRowTableData"
      :header-cell-style="{textAlign: 'center'}"
      :cell-class-name="cellClassName"
      style="width: 100%;"
      height="100%"
      stripe
      class="no-match-report-table"
      border>
      <el-table-column
        label="字段名称"
        width="200"
        prop="fieldNameStr">
        <template slot-scope="scope">
          <span
            style="font-weight: bold;">
            {{ scope.row.fieldNameStr }}
          </span>
          <!---->
        </template>
      </el-table-column>
      <el-table-column
        label="原始数据"
        prop="orgRelatedName">
        <template slot-scope="scope">
          <span>{{ scope.row.orgRelatedName }}</span>
          <span v-if="isShowErrorDetails(scope)" class="error-details fr" style="margin: 0 5px;">
            {{scope.row.errorTypeStr}}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="标准数据"
        prop="platformRelatedName">
      </el-table-column>
      <el-table-column
        v-if="showOperation"
        label="操作"
        prop="status"
        width="80"
        align="center">
        <!-- <template slot="header" slot-scope="scope">

        </template> -->
        <template slot-scope="scope">
          <!--第二版打分组件-->
          <el-popover
            :ref="'gradePopover' + scope.column.property + scope.$index"
            v-if="scope.row[scope.column.property] !== '1'"
            trigger="click"
            placement="bottom"
            :visible-arrow="false"
            popper-class="governance-poper"
            @hide="onHidePopover(scope, 'grade-cascader')"
            @show="onShowPopover(scope, 'grade-cascader')">
            <planar-grade
              :ref="'grade-cascader' + scope.column.property + scope.$index"
              :status="scope.row[scope.column.property]"
              :show-filter="isFilter"
              :currentClickLevel="scope.row[scope.column.property]"
              :filter-options="gradeOptions"
              :problem-type="problemTypeProp(scope)"
              :risk-level="riskLevelProp(scope)"
              :position="gradePosition[scope.column.property +''+ scope.row.drugId + '' + scope.$index]"
              v-if="showGradeComponent(scope)"
              @closeFilter="onCloseGradeFilter" />
            <div slot="reference">
              <svg-icon
                :icon-class="gradeIcon(scope.row[scope.column.property])"
                class="filter-icon"
                style="position: absolute; right: 16px;top: 50%;transform: translate(-50%, -50%);width: 18px;height: 18px;"
                @click="gradeIconClick(scope)"/>
            </div>
          </el-popover>
          <!--显示的是问号, 点击疑问状态切换到对号-->
          <svg-icon
            v-else-if="scope.row[scope.column.property] === '1'"
            :icon-class="gradeIcon(scope.row[scope.column.property])"
            class="filter-icon"
            fill-color="#0073E9"
            style="position: absolute; right: 16px;top: 50%;transform: translate(-50%, -50%);width: 18px;height: 18px;"
            @click="gradeIconClick(scope)"/>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onPrev">上一条</el-button>
      <el-button @click="onNext">下一条</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import {
    getCheckRecordListDataApi,
    updateRecordDataApi,
    publishReportApi
  } from '@/api/dm/platformGovernanceReport'
  import {
    CHECK_REPORT_QUERY_OBJ,
    UPDATE_COLUMN_OBJ
  } from '@/utils/dm/platformGovernanceReport/data'
  import {
    CHECK_REPORT_COLUMN,
    CHECK_REPORT_PROPERTY_COLUMN,
    CHECK_REPORT_STATUS_COLUMN
  } from '@/utils/dm/platformGovernanceReport/checkReportTable'
  import {
    FILTER_OPTIONS,
    GRADE_OPTIONS,
    TABLE_COLUMN_LIST,
    STATUS_OPTIONS,
    PROBLEM_OPTIONS,
    RISK_OPTIONS
  } from '@/utils/dm/institutionGovernanceReport/data'
  import PlanarGrade from '@/views/dm/institutionGovernanceReport/components/PlanarGrade'
  import FlatFilter from '@/views/dm/institutionGovernanceReport/components/FlatFilter'
  export default {
    props: {
      dialogVisible: {
        type: Boolean,
        required: true
      },
      originData: {
        type: Object
      },
      drugId: {
        type: String
      },
      // 后台返回的原始表格数据
      originTableData: {
        type: Array
      },
      gradeOptions: {
        type: Array
      },
      problemOptions: {
        type: Array
      },
      tableSearchObj: {
        type: Object
      },
      showOperation: {
        type: Boolean,
        default: true
      }
    },
    components: {
      PlanarGrade,
      FlatFilter
    },
    data() {
      return {
        batchGradeObj: {}, // 用来存储批量评审
        isFilter: false, // 是否开启筛选模式
        isBatch: false, // 是否批量评审
        total: 0,
        tableColumsList: this.$deepClone(CHECK_REPORT_COLUMN),
        CHECK_REPORT_PROPERTY_COLUMN: this.$deepClone(CHECK_REPORT_PROPERTY_COLUMN),
        CHECK_REPORT_STATUS_COLUMN: this.$deepClone(CHECK_REPORT_STATUS_COLUMN),
        currentRow: null, // 当前行
        tableData: [],
        cloneTableData: [],
        initTableData: [], // 表格获取到的接口数据，没有进行处理的
        currentRowItemByGrade: null, // 当前操作的表格数据
        currentScopeItemByGrade: null, // 当前打分操作scope
        gradePosition: {}, // 打分的位置
        updateColumnObj: this.$deepClone(UPDATE_COLUMN_OBJ), // 修改
        isIndeterminate: false,
        batchAll: false, // 是否全选
        currentScope: null, // 点击的当前scope
        visibleBatchPopover: false,
       /* 控制每个单元格中的 治理报告打分级联 组件是否渲染*/
        gradeCascaderShowFlag: {},
        filterChangeIcon: {}, // 用来切换每个对应的筛选图标
        showFilterObj: {},
        batchGradeObj: {}, // 用来存储批量评审
        filterRefsArr: [], // 用来存储筛选模式组件
        currentRowTableData: [], // 从审核治理报告表格中点击的一行数据构成的表格数据
        index: 0
      }
    },
    computed: {
      showBatchCheck: {
        get(scope) {
          return function (scope) {
            if (scope.column.property === 'drugId' && this.isBatch) {
              let arr = []
              let counts = 0
              for (let key in this.batchGradeObj) {
                counts++
                if (this.batchGradeObj[key] && key.indexOf('visibleBatchPopover') <= -1) {
                  return true
                } else if (!this.batchGradeObj[key] && key.indexOf('visibleBatchPopover') <= -1) {
                  arr.push(key)
                }
              }
              if (arr.length === counts / 2) {
                return false
              }
              // return true
            } else {
              return false
            }
          }
        },
        set(newVal) {
          // return function (scope) {
          //   console.log(scope)
          //   if (scope.column.property === 'drugId') {
          //     return newVal
          //   }
          // }
          if (!newVal) {
            this.currentRowTableData.forEach(item => item.checked = false)
            this.batchAll = false
            this.isIndeterminate = false
          }
          return newVal
        }
      },
      // 是否显示打分组件（第二版本）
      showGradeComponent() {
        return function (scope) {
          if (this.gradeCascaderShowFlag[scope.column.property+''+scope.row.drugId]) {
            return true
          } else {
            return false
          }
        }
      },
      gradeIcon: function () {
        return function (status) {
          if (Number(status) === 3) {
            // 失败
            return 'kc-empty'
          } else if (Number(status) === 2) {
            // 成功
            return 'duihao2'
          } else if (Number(status) === 1) {
            // 疑问
            return 'wenhao'
          } else {
            return ''
          }
        }
      },
       // 打分传参：问题类型
       problemTypeProp() {
        return function (scope) {
          console.log(scope)
          const propertyLen = scope.column.property.length
          let prop = scope.column.property.substring(0, propertyLen - 6) + 'errorType'
          if (scope.row && scope.row[prop]) {
            // return scope.row[prop]
            return scope.row.errorType
          } else {
            // 表示status不是失败，为”成功“或者”疑问“
            return ''
          }
        }
      },
      // 打分传参：风险级别
      riskLevelProp() {
        return function (scope) {
          console.log(scope)
          console.log(scope.row[scope.column.property])
          const propertyLen = scope.column.property.length
          let prop = scope.column.property.substring(0, propertyLen - 6) + 'riskLevel'
          if (scope.row && scope.row[prop]) {
            // return scope.row[prop]
            return scope.row.riskLevel
          } else {
            // 表示status不是失败，为”成功“或者”疑问“
            return ''
          }
        }
      },
      cellClassName() {
        return ({ row, column }) => {
          if (column.property !== 'fieldNameStr' && column.property !== 'status') {
            if (row.riskLevel === '4') {
              // 优化项
              return 'warning-color'
            } else if (row.riskLevel === '3') {
              // 风险项
              return 'error-color'
            }
          }
        }
      },
      // 是否显示问题类型
      isShowErrorDetails() {
        return (scope) => {
          if (scope.row.errorType) {
            return true
          } else {
            return false
          }
        }
      },
      // 判断是否是上海的机构
      isShangHaiDept() {
        const { provinceCode } = this.$store.getters.dept
        if (provinceCode === '310000') {
          return true
        } else {
          return false
        }
      }
    },
    created() {
      this.index = this.originTableData.findIndex(item => item.drugId === this.drugId)
      this.tableDataHandle()
      /* let data = this.originTableData.find(item => item.drugId === this.drugId)
      delete data.drugId
      for (let key in data) {
        let item = {}
        for (let dataKey in data[key]) {
          Object.assign(item, {
            [dataKey]: data[key][dataKey]
          })
        }
        
        Object.assign(item, {
          fieldName: key
        })
        this.currentRowTableData.push(item)
      } */
    },
    methods: {
      // 处理表格数据
      tableDataHandle() {
        this.currentRowTableData = []
        // let data = this.$deepClone(this.originTableData.find(item => item.drugId === this.drugId))
        // this.index = this.originTableData.findIndex(item => item.drugId === this.drugId)
        let data = this.$deepClone(this.originTableData[this.index])
        // console.log(this.originData)
        // console.log(this.originTableData)
        // console.log(data)
        delete data.drugId
        for (let key in data) {
          let item = {}
          for (let dataKey in data[key]) {
            Object.assign(item, {
              [dataKey]: data[key][dataKey]
            })
          }
          Object.assign(item, {
            fieldName: key
          })
          if (item.fieldName !== 'ustdCodeCompare' && item.fieldName !== 'shNedFlagCompare') {
            this.currentRowTableData.push(item)
          } else {
            if (!this.isShangHaiDept) {
              this.currentRowTableData.push(item)
            }
          }
        }
        // console.log(this.currentRowTableData)
      },
      handleClose() {
        this.$emit('update:dialogVisible', false)
      },
      onNext() {
        const len = this.originTableData.length
        if (this.index === len - 1) {
          this.$message({
            type: 'warning',
            message: '当前已经是最后一条数据'
          })
          return
        }
        this.index++
        this.tableDataHandle()
      },
      onPrev() {
        const len = this.originTableData.length
        if (this.index === 0) {
          this.$message({
            type: 'warning',
            message: '当前已经是第一条数据'
          })
          return
        }
        this.index--
        this.tableDataHandle()
      },
      // 打分事件
      async gradeIconClick(scope) {
        this.batchGradeObj[scope.column.property] = false
        this.batchGradeObj[scope.column.property + 'visibleBatchPopover'] = false
        this.showBatchCheck = false
        this.currentScopeItemByGrade = scope
        this.currentRowItemByGrade = scope.row
        this.currentScope = scope
        // 判断当前是对号，问好还是叉号
        const status = Number(scope.row[scope.column.property])
        if (status === 1) {
          // 疑问(点击疑问状态切换到对号)
          // this.gradeIcon = 'duihao2'
          await this.updateGrade(scope, '2')
        } else if (status === 2) {
          // 成功（点击成功状态切换到问号）
          this.onGradeFilterClick(scope)
        } else if (status === 3) {
          // 失败
          this.onGradeFilterClick(scope)
        }
      }, // 打分图标按钮
      onGradeFilterClick(scope) {
        // 点击时，才渲染当前单元格中的  治理报告打分级联 组件
        // 操作原本不存在的响应式属性时，使用 Vue.set(object, propertyName, value) 方法向嵌套对象添加响应式属性
        this.$set(this.gradeCascaderShowFlag, scope.column.property+''+scope.row.drugId, true)
        this.columnTableDataHandle(scope) // 当前打分图标展开
        this.currentRowItemByGrade = scope.row
        this.currentScopeItemByGrade = scope
        const refName = 'grade-cascader' + scope.column.property + scope.$index
        this.$nextTick(() => {
          if (this.$refs[refName]) {
            if (Array.isArray(this.$refs[refName])) {
              // 使用了v-for导致输出为数组
              this.$refs[refName][0] && this.$refs[refName][0].onFilterClick(scope)
              this.$refs[refName][1] && this.$refs[refName][1].onFilterClick(scope)
            } else {
              this.$refs[refName].onFilterClick(scope)
            }
          }
        })
      },
      
      // 打分修改
      async onUpdateGradeRecord(selectObj, scope) {
        // 处理数据

        // let item = this.initTableData.find(row => row.drugId === this.currentRowItemByGrade.drugId)
        // let finalItem = Object.assign(item, this.currentRowItemByGrade.applyId)
        // finalItem.applyId = this.tableSearchObj.applyId
        let problemList = ''
        let riskList = ''
        // 1.修改问题类型errorType,风险级别riskLevel；2.修改status，成功、疑问、失败
        let propertyLen = scope.column.property.length
        // let fieldName = scope.column.property.substring(0, propertyLen - 6)
        let fieldName = scope.row.fieldName
        this.updateColumnObj.applyId = this.tableSearchObj.applyId
        // this.updateColumnObj.drugId = this.currentRowItemByGrade.drugId
        this.updateColumnObj.fieldName = fieldName
        let drugIdArr = []
        // console.log(this.tableData)
        for (let item of this.currentRowTableData) {
          if (item.checked) {
            drugIdArr.push(item.drugId)
          }
        }
        if (drugIdArr.length === 0) {
          // 单个修改
          this.updateColumnObj.drugIdSet = [this.drugId]
        } else {
          // 批量修改
          this.updateColumnObj.drugIdSet = drugIdArr
        }
        // console.log(this.updateColumnObj)
        if (selectObj.parentItem) {
          //第一个版本的打分显示以及操作
          problemList = selectObj.checkList.problemList
          riskList = selectObj.checkList.riskList
          if (selectObj.parentItem.value !== '3') {
            // 打分项为成功和疑问的时候
            finalItem.status = selectObj.parentItem.value
            this.updateColumnObj.status = selectObj.parentItem.value
          } else {
            this.updateColumnObj.status = selectObj.parentItem.value
            this.updateColumnObj.errorType = selectObj.checkList.problemList
            this.updateColumnObj.riskLevel = selectObj.checkList.riskList
          }
        } else {
          // 第二个版本的打分显示以及操作
          problemList = selectObj.problemList
          riskList = selectObj.riskList
          this.updateColumnObj.status = selectObj.level
          this.updateColumnObj.errorType = selectObj.problemList
          this.updateColumnObj.riskLevel = selectObj.riskList
        }
        
        let res = await updateRecordDataApi(this.updateColumnObj)
        if (res.code === 200) {
          // await this.fetchData()
          // 不调查询的接口，前端手动处理修改的这一条数据
          /* this.currentRowTableData.find(item => {
            for (let drugId of this.updateColumnObj.drugIdSet) {
              if (this.drugId === drugId) {
                item[scope.column.property] = (selectObj.parentItem && selectObj.parentItem.value) || selectObj.level
                const flag = (selectObj.parentItem && selectObj.parentItem.value === '3') || (Number(selectObj.level) === 3)
                this.afterEditDataHandle(flag, item, fieldName, problemList, riskList)
              }
            }
          }) */
          const flag = (selectObj.parentItem && selectObj.parentItem.value === '3') || (Number(selectObj.level) === 3)
          this.afterEditDataHandle(flag, scope.row, fieldName, problemList, riskList, this.updateColumnObj.status)
          // scope.row[scope.column.property] = selectObj.parentItem.value
        }
      },
      // 打分修改调接口并处理数据
      async updateGrade(scope, status, selectObj) {
        // 6 就是status单词的长度
        let propertyLen = scope.column.property.length
        // let fieldName = scope.column.property.substring(0, propertyLen - 6)
        let fieldName = scope.row.fieldName
        this.updateColumnObj.applyId = this.tableSearchObj.applyId
        // this.updateColumnObj.drugId = scope.row.drugId
        let problemList = selectObj && selectObj.checkList ? selectObj.checkList.problemList : ''
        let riskList = selectObj && selectObj.checkList ? selectObj.checkList.riskList : ''
        this.updateColumnObj.drugId = this.drugId
        let drugIdArr = []
        for (let item of this.currentRowTableData) {
          if (item.checked) {
            drugIdArr.push(item.drugId)
          }
        }
        if (drugIdArr.length === 0) {
          // 单个修改
          // this.updateColumnObj.drugIdSet = [scope.row.drugId]
          this.updateColumnObj.drugIdSet = [this.drugId]
        } else {
          // 批量修改
          this.updateColumnObj.drugIdSet = drugIdArr
        }
        this.updateColumnObj.fieldName = fieldName
        if (Number(status) !== 3) {
          // 打分项为成功和疑问的时候
          // finalItem.status = selectObj.parentItem.value
          this.updateColumnObj.status = status
        } else {
          this.updateColumnObj.status = status
          this.updateColumnObj.errorType = selectObj.checkList.problemList
          this.updateColumnObj.riskLevel = selectObj.checkList.riskList
        }
        let res = await updateRecordDataApi(this.updateColumnObj)
        if (res.code === 200) {
          // await this.fetchData()
          // 不调查询的接口，前端手动处理修改的这一条数据
          /* this.currentRowTableData.find(item => {
            if (item.drugId === this.drugId) {
              item[scope.column.property] = status
              const flag = selectObj && selectObj.parentItem.value === '3'
              this.afterEditDataHandle(flag, item, fieldName, problemList, riskList, status)
            }
          }) */
          const flag = selectObj && selectObj.parentItem.value === '3'
          this.afterEditDataHandle(flag, scope.row, fieldName, problemList, riskList, status)
        }
      },
      // 修改成功之后，不调接口再获取数据，直接前端处理
      afterEditDataHandle(flag, item, fieldName, problemList, riskList, status) {
        if (flag) {
          // 打分项为叉号的时候修改对应的风险项和错误项字段
          item[fieldName + 'errorType'] = problemList
          item[fieldName + 'errorTypeStr'] = this.problemOptions.find(item => item.value === problemList).label
          item[fieldName + 'riskLevel'] = riskList
          item[fieldName + 'riskLevelStr'] = this.problemOptions.find(item => item.value === riskList).label

          item['errorType'] = problemList
          item['errorTypeStr'] = this.problemOptions.find(item => item.value === problemList).label
          item['riskLevel'] = riskList
          item['riskLevelStr'] = this.problemOptions.find(item => item.value === riskList).label
          item['status'] = status
          item['statusStr'] = STATUS_OPTIONS.find(item => item.value === status).label
        } else {
          item[fieldName + 'errorType'] = null
          item[fieldName + 'errorTypeStr'] = null
          item[fieldName + 'riskLevel'] = null
          item[fieldName + 'riskLevelStr'] = null

          item['errorType'] = null
          item['errorTypeStr'] = null
          item['riskLevel'] = null
          item['riskLevelStr'] = null
          item['status'] = status
          item['statusStr'] = STATUS_OPTIONS.find(item => item.value === status).label
        }
      },
      // 表格每一个列对应的打分列进行数据处理
      columnTableDataHandle(scope, type) {
        // 当column有值的时候表示操作的是当前列，当前列item[key + 'isFolded'] = false
        if (scope) {
          const propertyLen = scope.column.property.length
          let prop = scope.column.property.substring(0, propertyLen - 6) + 'isFolded'
          scope.row[prop] = false // 展开
          return
        }
        for (let key of TABLE_COLUMN_LIST) {
          this.cloneTableData.forEach(item => {
            item[key + 'isFolded'] = true // 打分的图标初始时为折叠状态
          })
        }
        if (this.currentRowTableData.length > 0 && type !== 'closeFilter' && type !== 'fetchData') {
          for (let key of TABLE_COLUMN_LIST) {
            this.currentRowTableData.forEach(item => {
              item[key + 'isFolded'] = true // 打分的图标初始时为折叠状态
            })
          }
        } else {
          this.currentRowTableData = this.$deepClone(this.cloneTableData)
        }
      },
      // 筛选模式使用的组件popover显示时的事件
      onShowPopover(scope, refName) {
        // console.log(this.$refs)
        let scrollLeft = document.getElementsByClassName('el-table__body-wrapper')[0].scrollLeft
        // console.log(scrollLeft)
        this.currentScope = scope
        // const filterName = 'filter' + scope.column.property + scope.$index
        const filterName = refName + scope.column.property + scope.$index
        // console.log(scope)
        this.$nextTick(() => {
          if (this.$refs[filterName]) {
            if (Array.isArray(this.$refs[filterName])) {
              // 使用了v-for导致输出为数组
              // this.$refs[filterName][0].updatePopper()
              // this.$refs[filterName][1].updatePopper()
              this.$refs[filterName][0].checkList = []
              this.$refs[filterName][0].isShowOptions = true
              this.$refs[filterName][1].checkList = []
              this.$refs[filterName][1].isShowOptions = true
              this.$refs[filterName][0].initHandleData && this.$refs[filterName][0].initHandleData(scope)
              this.$refs[filterName][1].initHandleData && this.$refs[filterName][1].initHandleData(scope)
            } else {
              // this.$refs[filterName].updatePopper()
              this.$refs[filterName].checkList = []
              this.$refs[filterName].isShowOptions = true
              this.$refs[filterName].initHandleData && this.$refs[filterName].initHandleData(scope)
            }
            // this.$refs['batchGradePopover' + scope.column.property + scope.$index][0].doClose()
            if (this.$refs['batchGradePopover' + scope.column.property + scope.$index]) {
              // 批量评审
              // console.log(this.$refs[filterName][0])
              // this.$refs[filterName][0].updatePopper()
              const popoverDom = this.$refs['batchGradePopover' + scope.column.property + scope.$index]
              popoverDom[1].$refs.popper.style.display = 'none'
              // console.log(popoverDom[0].$refs.popper.style.transform.translateX)
              // 滚动轴拖动，打分框位置计算会不正确
              popoverDom[0].$refs.popper.style.left = - (scrollLeft) + 'px'
              if (scope.$index >= this.tableColumsList.length - 1) {
                popoverDom[0].$refs.popper.style.left = - (scrollLeft) - 220 + 'px'
              }
              // popoverDom[0].$refs.popper.style.transform.translateX = 'none'
            }
            
            // this.$refs['batchGradePopover' + scope.column.property + scope.$index][1].popperElm.style.display = 'none'
            // this.$refs['batchGradePopover' + scope.column.property + scope.$index][1].destroyPopper()
            // for (const key in this.$refs) {
            //   if (key.indexOf('batchGradePopover') > -1) {
            //     this.$refs[key] && this.$refs[key].doClose()
            //   }
            // }
          }
        })
      },
      // 筛选模式使用的组件popover隐藏时的事件
      onHidePopover(scope, refName = 'filter-cascader') {
        // const filterName = 'filter' + scope.$index
        const filterName = refName + scope.column.property + scope.$index
        this.$nextTick(() => {
          this.columnTableDataHandle()
          if (this.$refs[filterName]) {
            if (Array.isArray(this.$refs[filterName])) {
              // 使用了v-for导致输出为数组
              this.$refs[filterName].filterOptions && this.$refs[filterName][0].filterOptions.forEach(option => {
                option.showChildren = false
              })
            } else {
              this.$refs[filterName].filterOptions && this.$refs[filterName].filterOptions.forEach(option => {
                option.showChildren = false
              })
            }
          }
          if (this.filterOptions) {
            this.filterOptions.forEach(option => {
              option.showChildren = false
            })
          }
        })
      },
      // 打分下拉项关闭
      async onCloseGradeFilter(selectObj, scope) {
        // this.visibleBatchPopover = false
        // this.$set(this.batchGradeObj, scope.column.property + 'visibleBatchPopover', false)
        if (this.batchGradeObj[scope.column.property] && selectObj) {
          let arr = this.currentRowTableData.filter(item => item.checked)
          if (!arr || arr.length === 0) {
            this.$message({
              type: 'warning',
              message: '请至少勾选一条数据'
            })
            return
          }
        }
        this.batchGradeObj[scope.column.property] = false
        this.batchGradeObj[scope.column.property + 'visibleBatchPopover'] = false
        // this.$set(this.batchGradeObj, scope.column.property, false)
        // this.$set(this.batchGradeObj, 'drugId', false)
        this.currentScope = null
        // this.columnTableDataHandle()
        const refName = 'gradePopover' + scope.column.property + scope.$index
        this.$nextTick(() => {
          if (this.$refs[refName]) {
            if (Array.isArray(this.$refs[refName])) {
              // 使用了v-for导致输出为数组
              this.$refs[refName][0] && this.$refs[refName][0].doClose()
              this.$refs[refName][1] && this.$refs[refName][1].doClose()
            } else {
              this.$refs[refName] && this.$refs[refName].doClose()
            }
          }
        })
        this.filterVisible = false
        const propertyLen = scope.column.property.length
        if (selectObj) {
          await this.onUpdateGradeRecord(selectObj, scope)
        }
        this.showBatchCheck = false
        // 修改打分
      },
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep td.error-color {
    background-color: #FFDBE1 !important;
  }
  ::v-deep td.warning-color {
    background-color: #FFE9D1 !important;
  }
  ::v-deep td .cell .error-details {
    display: inline-block;
    /* padding: 5px !important; */
    color: #2B60F8;
    background-color: #D7E3FA;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    width: 58px;
    border-radius: 3px;
  }
  .simple-check-report-dialog {
    ::v-deep.el-dialog {
      height: 88% !important;
    }
  }
</style>