/**平台治理报告 */
// 报告列表查询条件
export const QUERY_OBJ = {
  timeList: [],
  startTime: '', // 开始时间 yyyy-MM-dd
  endTime: '', // 结束时间 yyyy-MM-dd
  pageNum: 1, // 当前记录起始索引
  pageSize: 10, // 每页显示记录数
  searchValue: '', // 机构名称,在平台端使用
  status: '' // 状态 1:报告生成中 2:已发布
}
export const STATUS_OPTIONS = [
  {
    value: '',
    label: '全部'
  },
  {
    value: '1',
    label: '生成中'
  },
  {
    value: '2',
    label: '已发布'
  }
]
// 审核报告分页查询条件
export const CHECK_REPORT_QUERY_OBJ = {
  applyId: '', // 申请ID
  errorCodeList: [], // 错误原因-使用code
  pageNum: 1, // 当前记录起始索引
  pageSize: 10, // 每页显示记录数
  searchValue: '', // 编号/药品通用名
  filterList: [],
  filterErrorTypeList: [], // 筛选模式---问题类型集合
  filterFieldName: [], // 筛选模式---需要增加过滤条件的字段名称
  filterRiskLevelList: [], // 筛选模式---风险层级集合
}
// 审核报告修改
export const UPDATE_COLUMN_OBJ = {
  applyId: '',
  drugId: '',
  drugIdSet: [],
  errorType: '', // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
  fieldName: '',
  riskLevel: '', // 风险级别 3为风险项 4为优化项,可用值:3,4
  status: '' // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
}
