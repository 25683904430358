/**平台数据治理 */
import request from '@/utils/request'

// 根据查询条件获取治理记录列表
export function getRecordListDataApi(data) {
  return request({
    url: '/dm/platform/eval/match/record/list',
    method: 'GET',
    params: data
  })
}
// 机构相关列表查询-支持五笔,拼音,模糊搜索,不传提供前50条数据
export function getOrgDataApi(data) {
  return request({
    url: '/dm/platform/eval/org/search',
    method: 'GET',
    params: data
  })
}
// 获取治理报告
export function getCheckRecordListDataApi(data) {
  return request({
    url: '/dm/platform/eval/result/list',
    method: 'post',
    data: data,
    // loading: true
  })
}
// 数据治理相关内容修改
export function updateRecordDataApi(data) {
  return request({
    url: '/dm/platform/eval/update/field',
    method: 'PUT',
    data: data
  })
}
// 治理报告发布 /dm/platform/eval/publish
export function publishReportApi(data) {
  return request({
    url: '/dm/platform/eval/publish',
    method: 'PUT',
    params: data
  })
}

// 健康指数、雷达分析
export function getPlatformScoreApi(applyId) {
  return request({
    url: '/dm/platform/eval/health/info/' + applyId,
    method: 'get'
  })
}
// 匹配情况(匹配项、未匹配项)
export function getPlatformMatchSituationApi(applyId){
  return request({
    url: '/dm/platform/eval/match/chart/' + applyId,
    method: 'get'
  })
}
// 匹配项
export function getPlatformMatchItemApi(applyId){
  return request({
    url: '/dm/platform/eval/match/statistic/' + applyId,
    method: 'get'
  })
}
// 未匹配原因
export function getPlatformNoMatchReasonApi(applyId){
  return request({
    url: '/dm/platform/eval/notMatch/statistic/' + applyId,
    method: 'get'
  })
}
// 历史版本
export function getPlatformHistoryListApi(data){
  return request({
    // url: '/dm/platform/eval/record/list',
    url: '/dm/platform/eval/match/record/list',
    method: 'get',
    params: data
  })
}
// 未匹配项中审核报告的分页列表查询
export function getPlatformNoMatchRecordListApi(data){
  return request({
    url: '/dm/platform/eval/noMatch/record/list',
    method: 'get',
    params: data
  })
}
// 匹配项中审核报告的分页列表查询
// export function getPlatformMatchRecordListApi(data){
//   return request({
//     url: '/dm/platform/eval/result/list',
//     method: 'post',
//     data: data
//   })
// }
